import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Heading from "@ui/heading";
import Text from "@ui/text";
import { DocIntroWrapper, DocIntroBox } from "./style";
import Image from "@ui/image";
import Button from "@ui/button";

const DocIntro = ({ data }) => {
  return (
    <DocIntroWrapper id={data.section}>
      <Container>
        <Row className="mb-5">
          <Col lg={12}>
            <SectionTitle
              subtitle={data.section_title?.subtitle}
              title={data.section_title?.title}
              description={data.section_title?.description}
            />
            <Text>{data.texts[0]?.content}</Text>
          </Col>
        </Row>
        <Row className="my-2">
          {data.items.map((dat, index) => (
              <Col lg={3} key={index}>
                <DocIntroBox>
                  <Image src={dat.images[0].src} alt={"docintro" + index} />
                  <Heading as="h4">{dat.title}</Heading>
                </DocIntroBox>
              </Col>
          ))}
        </Row>
        <Row>
          <Col lg={12} className="text-center mt-4">
            {data.buttons &&
              data?.buttons?.map(({ id, content, ...rest }) => (
                <Button key={id} m="7px" {...rest}>
                  {content}
                </Button>
              ))}
          </Col>
        </Row>
      </Container>
    </DocIntroWrapper>
  );
};
export default DocIntro;
