import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Heading from "@ui/heading";
import Button from "@ui/button";
import {
  DocTranslatorsWrapper,
  DocTranslatorBox,
  DocTranslatorBoxInner,
} from "./style";

export default function DocTranslators({ data }) {
  return (
    <DocTranslatorsWrapper id={data.section}>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              subtitle={data.section_title.subtitle}
              title={data.section_title.title}
              description={data.section_title.description}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <DocTranslatorBox>
              {data.items.map((item, index) => (
                <DocTranslatorBoxInner key={index}>
                  <Heading as="h4">{item.title}</Heading>
                </DocTranslatorBoxInner>
              ))}
            </DocTranslatorBox>
          </Col>
          <Col lg={12} className="text-center mt-4">
            {data.buttons &&
              data?.buttons?.map(({ id, content, ...rest }) => (
                <Button key={id} m="7px" {...rest}>
                  {content}
                </Button>
              ))}
          </Col>
        </Row>
      </Container>
    </DocTranslatorsWrapper>
  );
}
