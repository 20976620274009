import styled, { themeGet, device } from "@styled";

export const DocTranslatorsWrapper = styled.section`
  padding: 100px 0;
  background: #de5307;
  .section-title {
    h6 {
      color: #fff;
    }
    h2 {
      border-color: #fff;
    }
    p {
      color: #fff;
    }
  }
`;
export const DocTranslatorBox = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  width: 100%;
  ${device.small} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.large} {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const DocTranslatorBoxInner = styled.div`
  background: rgb(255 255 255 / 11%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
  border-radius: 6px;
  cursor: pointer;
  transition: ${themeGet("transition")};
  &:hover {
    box-shadow: 0 0 40px -16px rgb(255 255 255);
    transform: translateY(-3px);
  }
  h4 {
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    margin-left: 15px;
  }
`;
