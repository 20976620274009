import styled, { device } from "@styled";

export const DocIntroWrapper = styled.section`
  padding: 90px 0;
  background: #f8f8f8;
  p {
    font-size: 18px;
    line-height: 1.4;
    margin-block-start: 15px;
    ${device.medium} {
      margin-block-start: 30px;
      font-size: 18px;
      line-height: 1.4;
    }
    ${device.large} {
      font-size: 22px;
      line-height: 1.6;
    }
  }
`;
export const DocIntroBox = styled.section`
  display: flex;
  align-items: center;
  background: #f8f8f8;
  margin: 20px 0;
  h4 {
    color: #343434;
    font-size: 22px;
    font-weight: bold;
    margin-left: 15px;
  }
`;
